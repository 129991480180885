var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.actesTypes.length)?_c('div',[((!_vm.isPerfsTab || !_vm.hasTrainerAccess))?_c('CustomTable',{ref:"table",attrs:{"id_table":_vm.id_table,"transformer":['ActeTransformer', 'withNotes'],"busy":_vm.table_busy,"hrefsRoutes":_vm.config_table_hrefs,"primaryKey":"actes_id","base-filters":_vm.filters,"date-filter":_vm.dateFilter,"externSlotColumns":['notes.type_fonts', 'ordonnance'],"persistDateFilter":true,"dateRangeV2":true,"periodToShow":[ 'personalise', 'mois_dernier', 'semaine_derniere', 'hier' ]},on:{"update:busy":function($event){_vm.table_busy=$event}},scopedSlots:_vm._u([{key:"custom-slot-cell(notes.type_fonts)",fn:function(ref){
var data = ref.data;
return [(data.notes && Object.keys(data.notes.type_fonts).length > 0)?_c('div',{staticClass:"previewColIdentifier",on:{"click":function($event){return _vm.catchColumnNotesShutter(data)}}},_vm._l((data.notes.type_fonts),function(font,key){return _c('span',{key:key},[_c('font-awesome-icon',{attrs:{"icon":font.font}}),_vm._v(" "+_vm._s(font.occurences)+" ")],1)}),0):_vm._e()]}},{key:"custom-slot-cell(ordonnance)",fn:function(ref){
var data = ref.data;
return [(data.actes_document_lien.length > 0)?_c('div',_vm._l((data.actes_document_lien),function(document){return _c('div',{key:document.document_id},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.viewDocumentOrdonnance(document)}}},[_vm._v(" "+_vm._s(document.document_name)+" ")])])}),0):(data.actetype.actestype_needordonnance)?_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.addOrdonnance([data])}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'file-medical-alt']}})],1)]):_vm._e()]}}],null,true)}):_vm._e(),(_vm.isPerfsTab && _vm.hasTrainerAccess)?_c('CustomTable',{ref:"table_perf",attrs:{"id_table":"liste_actes_performance","transformer":['ActeTransformer', 'withNotes'],"busy":_vm.table_busy,"hrefsRoutes":_vm.config_table_hrefs,"primaryKey":"actes_id","base-filters":_vm.filters,"date-filter":_vm.dateFilter,"externSlotColumns":['notes.type_fonts', 'ordonnance'],"buttonActionEventOnly":true,"persistDateFilter":true},on:{"update:busy":function($event){_vm.table_busy=$event}},scopedSlots:_vm._u([{key:"custom-slot-cell(ordonnance)",fn:function(ref){
var data = ref.data;
return [(data.actes_document_lien.length > 0)?_c('div',_vm._l((data.actes_document_lien),function(document){return _c('div',{key:document.document_id},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.viewDocumentOrdonnance(document)}}},[_vm._v(" "+_vm._s(document.document_name)+" ")])])}),0):(data.actetype.actestype_needordonnance)?_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.addOrdonnance([data])}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'file-medical-alt']}})],1)]):_vm._e()]}}],null,true)}):_vm._e(),_c('b-modal',{ref:"ordonnanceModal",attrs:{"size":"xl","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("ordonnance.ajouter_ordonnance"))+" ")]},proxy:true}],null,false,4066965252)},[_c('OrdonnanceForm',{attrs:{"horses_ids":_vm.horse_ids,"from_mediatheque":false},on:{"documents":_vm.handleOrdoForm}})],1),_c('b-modal',{ref:"ordonnanceRenameModal",attrs:{"size":"xl","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("ordonnance.ajouter_ordonnance"))+" ")]},proxy:true}],null,false,4066965252)},[_c('OrdonnanceRename',{attrs:{"horses_ids":_vm.horse_ids,"from_mediatheque":false,"documents":_vm.documents,"acte_ids":_vm.acte_ids},on:{"update:documents":function($event){_vm.documents=$event},"success":_vm.HandleOrdoRename}})],1),_c('b-modal',{ref:"modalViewDocumentOrdonnance",attrs:{"modal-class":'modal-pdf',"size":"xl","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('h2',{staticClass:"mx-auto my-auto"},[_vm._v(_vm._s(_vm.$t('ordonnance.document_visualisation')))]),_c('i',{staticClass:"btn btn-link my-auto",on:{"click":function($event){return close()}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'times-circle']}})],1)]}}],null,false,2641914278)},[_c('OfflineMedia',{attrs:{"filename":_vm.modal_document_filename,"customClass":"w-100"}})],1),_c('ModalCourrierActes',{ref:"modal_courrier_actes"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }