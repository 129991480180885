<template>
    <div v-if="actesTypes.length">
		<CustomTable
			v-if="(!isPerfsTab || !hasTrainerAccess)"
			ref="table"
			:id_table="id_table"
			:transformer="['ActeTransformer', 'withNotes']"
			:busy.sync="table_busy"
			:hrefsRoutes="config_table_hrefs"
			primaryKey="actes_id"
			:base-filters="filters"
			:date-filter="dateFilter"
			:externSlotColumns="['notes.type_fonts', 'ordonnance']"
			:persistDateFilter="true"
			:dateRangeV2="true"
			:periodToShow="[ 'personalise', 'mois_dernier', 'semaine_derniere', 'hier' ]"
		>
			<template v-slot:[`custom-slot-cell(notes.type_fonts)`]="{ data }">
				<div 
					v-if="data.notes && Object.keys(data.notes.type_fonts).length > 0" 
					class="previewColIdentifier"
					@click="catchColumnNotesShutter(data)"	
				>
					<span 
						v-for="(font, key) in data.notes.type_fonts"	
						:key="key"
					>
						<font-awesome-icon :icon="font.font" /> {{ font.occurences }}
					</span>
				</div>
			</template>
			<template v-slot:[`custom-slot-cell(ordonnance)`]="{ data }">
				<div 
					v-if="data.actes_document_lien.length > 0" 
				>
					<div v-for="document of data.actes_document_lien" :key="document.document_id">
						<a href="#" @click="viewDocumentOrdonnance(document)">
							{{ document.document_name }}
						</a>
					</div>
					
				</div>
				<div 
					v-else-if="data.actetype.actestype_needordonnance" 
				>
					<a href="#" @click="addOrdonnance([data])">
						<font-awesome-icon :icon="['far', 'file-medical-alt']" />
					</a>
				</div>
			</template>
		</CustomTable>

		<CustomTable
			v-if="isPerfsTab && hasTrainerAccess"
			ref="table_perf"
			id_table="liste_actes_performance"
			:transformer="['ActeTransformer', 'withNotes']"
			:busy.sync="table_busy"
			:hrefsRoutes="config_table_hrefs"
			primaryKey="actes_id"
			:base-filters="filters"
			:date-filter="dateFilter"
			:externSlotColumns="['notes.type_fonts', 'ordonnance']"
			:buttonActionEventOnly="true"
			:persistDateFilter="true"
		>
			<template v-slot:[`custom-slot-cell(ordonnance)`]="{ data }">
				<div 
					v-if="data.actes_document_lien.length > 0" 
				>
					<div v-for="document of data.actes_document_lien" :key="document.document_id">
						<a href="#" @click="viewDocumentOrdonnance(document)">
							{{ document.document_name }}
						</a>
					</div>
					
				</div>
				<div 
					v-else-if="data.actetype.actestype_needordonnance" 
				>
					<a href="#" @click="addOrdonnance([data])">
						<font-awesome-icon :icon="['far', 'file-medical-alt']"  />
					</a>
				</div>
			</template>
		</CustomTable>


		<b-modal size="xl" ref="ordonnanceModal" hide-footer>
            <template v-slot:modal-title>
                {{ $t("ordonnance.ajouter_ordonnance") }}
            </template>
			<OrdonnanceForm :horses_ids="horse_ids" :from_mediatheque="false" @documents="handleOrdoForm"/>
        </b-modal>

		<b-modal size="xl" ref="ordonnanceRenameModal" hide-footer>
            <template v-slot:modal-title>
                {{ $t("ordonnance.ajouter_ordonnance") }}
            </template>
			
			<OrdonnanceRename :horses_ids="horse_ids" :from_mediatheque="false" :documents.sync="documents" @success="HandleOrdoRename" :acte_ids="acte_ids"/>
        </b-modal>

		<b-modal :modal-class="'modal-pdf'" size="xl" ref="modalViewDocumentOrdonnance" hide-footer>
            <template slot="modal-header" slot-scope="{ close }">
                <h2 class="mx-auto my-auto">{{ $t('ordonnance.document_visualisation') }}</h2>
                <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'times-circle']" /></i>
            </template>

			<OfflineMedia
				:filename="modal_document_filename"
				customClass="w-100"
			/>
        </b-modal>

		<ModalCourrierActes ref="modal_courrier_actes"/>
    </div>
</template>

<script>
import Navigation from "@/mixins/Navigation.js"
import Shutter from "@/mixins/Shutter.js"
import ShutterNotes from "@/mixins/shutters-manager/Notes.js"
import Documents from "@/mixins/Documents.js"
import Constants from 'Constants'
import { EventBus } from 'EventBus'

export default {
	name: 'HistoryAct',
    mixins: [Navigation, Shutter, ShutterNotes, Documents],
    props: {
        actesTypes: { type: Array, default: () => ([]) },
		isPerfsTab: { type: Boolean, default: false },
    },
    data() {
        return {
            /* Items and data */
			id_table: "liste_actes",
            column_previewable: ['horse_nom'],
			add_slot_cols: ['notes.type_fonts'],
			edit_slot_cols: ['notes.type_fonts'],
			events_tab: {
				'TableAction::goToAddOrdonnanceFromAct': this.addOrdonnance,
				'TableAction::goToPrintActs': this.openModalCourrierActes
			},
            /* Table config : */
            table_busy: true,
            config_table_hrefs: {
				'horse_nom': {
					routeUniqueName: 'horseFiche',
                    routeName: 'horseFiche',
                    params: {
						horse_id: 'actes_horse'
                    }
                },
				'entity_rs': {
					routeUniqueName: 'tiersFiche',
					routeName: 'tiersFiche',
					params: {
						tiers_id: 'entity_id'
					}
				}
            },
			dateFilter: {
				column: 'actes_date',
				start: null,
				end: null
			},
			reloadMethod: 'refreshTable',
			documents: [],
			horse_ids: [],
			acte_ids: [],
			modal_document_filename: null,
        }
	},
	created() {
		if(this.getConfig('startDate' + this.id_table)) {
			this.dateFilter.start = new Date(this.getConfig('startDate' + this.id_table))
		}
		else {
			this.dateFilter.start = new Date(new Date().setMonth(new Date().getMonth() - 3))
		}

		if(this.getConfig('endDate' + this.id_table)) {
			this.dateFilter.end = new Date(this.getConfig('endDate' + this.id_table))
		}
	},
	computed: {
		filters() {
			return {
				is_validated: {
					column: 'actes_actesstatut',
					operator: 'isEqualTo',
					value: 1
				},
				is_in_group: {
					column: 'actes_actestype',
					operator: 'isInList',
					value: this.actesTypes
				},
				is_not_archived: {
					column: 'horse_inactive',
					operator: 'isEqualTo',
					value: 0
				}
			}
		},
		hasTrainerAccess() {
            return this.$store.state.userAccess.hasTrainerAccess
        },
	},
    methods: {
		refreshTable() {
			if(this.$refs.table) {
				this.$refs.table.refreshTable()
			}
			if(this.$refs.table_perf) {
				this.$refs.table_perf.refreshTable()
			}
		},

		catchColumnNotesShutter(act) {
			this.handleNotePreview(act.notes.raw)
		},

		addOrdonnance(actes) {
			this.horse_ids = actes.map(a => a.actes_horse)
			this.acte_ids = actes.map(a => a.actes_id)
			this.$refs.ordonnanceModal.show()
		},

		handleOrdoForm(documents) {
			this.$refs.ordonnanceModal.hide()
			this.documents = documents
			this.$refs.ordonnanceRenameModal.show()
		},

		HandleOrdoRename() {
			this.$refs.ordonnanceRenameModal.hide()
			if(this.isPerfsTab && this.hasTrainerAccess){
				this.$refs.table_perf.refreshTable()
			}else{
				this.$refs.table.refreshTable()
			}
		},

		openModalCourrierActes(acte_ids) {
			this.$refs.modal_courrier_actes.openModal(acte_ids)
		},

		async viewDocumentOrdonnance(data) {
				if(this.asking_write_access) return

				if(!this.got_write_access) {
					this.interracting_data = data
					this.action_name = 'viewDocumentOrdonnance'
					return this.askPermissionAndroid()
				}

				const filename = data.document_filename

				if((Constants.IS_IOS_RUNNING || Constants.IS_AND_RUNNING)
					&& filename.includes('.pdf')
				) {
					const blobFile = await this.$sync.loadOnDemandFile(filename)
					let reader = new FileReader()
					reader.readAsDataURL(blobFile)

					reader.onloadend = () => {
						const base64Cleanedup = this.formatBase64String(reader.result)

						if(Constants.IS_IOS_RUNNING) {
							window.nativeComm.fetchNative(() => {
								EventBus.$emit('TableAction::stopSpin')
							}, {
								filename: filename,
								base64: base64Cleanedup
							}, 'previewPdfFile')
						}
						else if(Constants.IS_AND_RUNNING) {
							window.nativeComm.fetchNative(res => {
								EventBus.$emit('TableAction::stopSpin')
							}, base64Cleanedup, 'previewPdfDocument')
						}
					}
				}
				else {
					this.$refs['modalViewDocumentOrdonnance'].show()
                	this.modal_document_filename = filename
					EventBus.$emit('TableAction::stopSpin')
				}
            },
    },
    components: {
        CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		OrdonnanceForm: () => import("@/components/Documents/OrdonnanceForm"),
		OrdonnanceRename: () => import("@/components/Documents/OrdonnanceRenameForm"),
		OfflineMedia: () => import('GroomyRoot/components/Utils/OfflineMedia'),
		ModalCourrierActes: () => import('@/components/Modals/ModalCourrierActes')
    }
}
</script>
